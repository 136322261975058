@import url('https://fonts.googleapis.com/css2?family=Quattrocento+Sans:wght@400;700&display=swap');

body {
  font-family: 'Quattrocento Sans', sans-serif;
  background-color: #2c2c2c;
  color: #f0f0f0;
  margin: 0;
  padding: 20px;
}

.App {
  margin: 0 auto;
  padding: 20px;
  max-width: 90%;
  background-color: #3c3c3c;
  border-radius: 8px;
}

.logo {
  width: 50px;
  height: auto;
  vertical-align: middle;
  margin-right: 10px;
}

.disclaimer-instructions {
  margin-top: 20px;
}

.disclaimer-instructions h2 {
  margin-bottom: 5px;
}

.disclaimer-instructions p,
.disclaimer-instructions ul {
  margin-bottom: 15px;
}

form {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the form elements */
  width: 100%;
}

textarea {
  width: 100%;
  padding: 15px;
  font-size: 1.2em;
  border: 2px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  resize: vertical;
  font-family: 'Quattrocento Sans', sans-serif;
  background-color: #4c4c4c;
  color: #f0f0f0;
  box-sizing: border-box;
}

textarea::placeholder {
  color: #f0f0f0; /* White colored placeholder text */
}

.buttons {
  display: flex;
  justify-content: center; /* Center the buttons */
  gap: 10px;
  width: 100%; /* Ensure the buttons take up the available width */
}

button[type="submit"],
button[type="button"] {
  padding: 15px 25px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-family: 'Quattrocento Sans', sans-serif;
}

button[type="button"] {
  background-color: #6c757d;
}

button[type="submit"]:hover,
button[type="button"]:hover {
  background-color: #0056b3;
}

button[type="button"]:hover {
  background-color: #5a6268;
}

@media (max-width: 600px) {
  .App {
    padding: 10px;
  }

  textarea {
    padding: 10px;
    font-size: 1em;
  }

  button[type="submit"],
  button[type="button"] {
    padding: 10px 15px;
    font-size: 0.9em;
  }
}
